<template>
	<div>
		<div class="d-flex justify-content-between align-items-center">
			<div v-if="item.editors && item.editors.length" class="editor__item">
				<span
					v-for="editor in item.editors"
					:key="editor.id"
					class="mr-3 editor-selected"
					@click="showPopupTable(editor)"
				>
					{{ editor.nickname }}
				</span>
			</div>
			<button
				:class="{ 'justify-content-end': !item.editors.length }"
				class="px-2 btn btn-outline-custom btn__edit"
				@click="editEditor"
			>
				<font-awesome-icon :icon="['fas', 'pencil-alt']" :title="$t('Action.Edit')" />
			</button>
		</div>
		<CustomModal
			v-if="showPopup"
			:title="$t('EditorPayrollTitle.SelectEditor')"
			:submit-text="setButtonText"
			@submit-modal="save"
			@cancel-modal="cancel"
		>
			<template #content>
				<form class="d-flex mb-3">
					<div class="search-input">
						<input
							v-model="searchInput"
							type="text"
							class="form-control"
							:placeholder="$t('EditorPayrollFormFields.search')"
							@input="searchDebounce"
						/>
						<span v-if="searchInput" class="icon-close" @click="clearSearch">
							<font-awesome-icon :icon="['fas', 'times']" />
						</span>
					</div>
				</form>
				<CListGroup class="list-editor">
					<CListGroupItem
						v-for="editor in listEditor"
						:key="editor.id"
						:class="{ 'disabled-editor': !editor.paysheetId }"
						class="d-flex"
					>
						<b-form-checkbox
							:id="`checkbox-${editor.id}`"
							v-model="editorSelected[editor.id]"
							:name="`checkbox-${editor.id}`"
							class="checkbox"
							:disabled="!editor.paysheetId"
						/>
						<span class="ml-2">{{ editor.nickname }}</span>
					</CListGroupItem>
				</CListGroup>
			</template>
		</CustomModal>
		<CustomModal
			v-if="showTable"
			:title="editorUpdateAmount.nickname"
			:submit-text="$t('Button.Save')"
			@submit-modal="confirmModal"
			@cancel-modal="cancelModal"
		>
			<template #content>
				<div class="table-responsive-md">
					<table class="table table-bordered table-hover table-responsive table-fit">
						<tbody>
							<tr v-for="(editor, index) in editorOfPaysheetDetail" :key="editor.orderTypeId">
								<td scope="row" class="text-break text-center fit-id">{{ editor.orderTypeId }}</td>
								<td scope="row" class="text-break">
									<div class="amount-wrap-input">
										<input
											v-model="editor.amount"
											type="text"
											class="amount-input"
											@keydown="handleKeydown"
										/>
										<span
											class="icon-up icon"
											@click="increase(editor.amount, index, editor.orderTypeId)"
										>
											<font-awesome-icon :icon="['fas', 'caret-up']" />
										</span>
										<span
											class="icon-down icon"
											@click="deincrease(editor.amount, index, editor.orderTypeId)"
										>
											<font-awesome-icon :icon="['fas', 'caret-down']" />
										</span>
									</div>
								</td>
							</tr>
						</tbody>
					</table>
				</div>
			</template>
		</CustomModal>
	</div>
</template>

<script>
import { debounce, uniqBy } from "lodash-es"
import { createNamespacedHelpers } from "vuex"
import { AMOUNT_STEP, PARAMS } from "../../../shared/plugins/constants"
import { GET_EDITORS } from "../../editors/store/action-types"
import {
	ADD_EDITOR_OF_PAYROLL,
	GET_EDITOR_OF_PAYROLL_DETAIL,
	UPDATE_EDITOR_OF_PAYROLL,
} from "../store/action-types"
import { SET_STATE } from "../../editors/store/mutation-types"
const {
	mapState: editorsState,
	mapActions: editorsActions,
	mapMutations: editorsMutations,
} = createNamespacedHelpers("editors")
const { mapActions } = createNamespacedHelpers("editorPayroll")
export default {
	name: "SelectEditor",
	props: {
		item: {
			type: Object,
			default: () => {},
		},
		id: {
			type: [String, Number],
			default: null,
		},
		amounts: {
			type: Array,
			default: () => [],
		},
	},
	data() {
		return {
			limit: PARAMS.LIMIT,
			offset: PARAMS.OFFSET,
			keyword: null,
			isEdit: false,
			editorSelected: {},
			showTable: false,
			editorUpdateAmount: null,
			showPopup: false,
			searchInput: null,
			editorId: null,
			editorOfPaysheetDetail: null,
			missingAmounts: {},
			listEditor: [],
		}
	},
	computed: {
		...editorsState(["editors"]),
		setButtonText() {
			if (this.item.editors?.length) {
				return this.$t("Button.Update")
			}
			return this.$t("Button.Save")
		},
	},
	watch: {
		isEdit(val) {
			if (val && this.item.editors?.length) {
				this.item.editors.forEach(el => {
					this.editorSelected[el.id] = true
				})
			}
		},
		amounts: {
			handler(val) {
				if (val?.length) {
					val.forEach(el => {
						this.missingAmounts[el.orderTypeId] = el.missingAmount
					})
				}
			},
			deep: true,
		},
	},
	created() {
		if (this.item.editors?.length) {
			this.item.editors.forEach(el => {
				this.editorSelected[el.id] = true
			})
		}

		if (this.amounts?.length) {
			this.amounts.forEach(el => {
				this.missingAmounts[el.orderTypeId] = el.missingAmount
			})
		}
	},
	methods: {
		...mapActions({
			ADD_EDITOR_OF_PAYROLL,
			GET_EDITOR_OF_PAYROLL_DETAIL,
			UPDATE_EDITOR_OF_PAYROLL,
		}),
		...editorsActions({ GET_EDITORS }),
		...editorsMutations({ SET_STATE }),
		async save() {
			const editorIds = []

			for (const property in this.editorSelected) {
				if (this.editorSelected[property]) {
					editorIds.push(property)
				}
			}

			const params = {
				editorIds,
				orderId: this.id,
			}

			const res = await this.ADD_EDITOR_OF_PAYROLL(params)
			res && this.cancel()
		},
		async editEditor() {
			this.listEditor = this.editors
			await this.setEditors()
			this.isEdit = true
			this.showPopupSelectEditor()
		},
		setEditors() {
			return new Promise(resolve => {
				this.listEditor = uniqBy([...this.item.editors].concat(this.listEditor), "id")
				resolve()
			})
		},
		showPopupSelectEditor() {
			this.showPopup = true
			this.$store.commit("set", ["modalShow", true])
		},
		async showPopupTable(editor) {
			this.editorId = editor.id
			this.editorUpdateAmount = editor
			await this.getEditorOfPayrollDetail()
			this.showTable = true
			this.$store.commit("set", ["modalShow", true])
		},
		async getEditorOfPayrollDetail() {
			const params = {
				editorId: this.editorId,
				orderId: this.id,
			}
			this.editorOfPaysheetDetail = await this.GET_EDITOR_OF_PAYROLL_DETAIL(params)
		},
		async confirmModal() {
			const params = {
				editorId: this.editorId,
				orderId: this.id,
				orderTypes: this.editorOfPaysheetDetail,
			}

			const res = await this.UPDATE_EDITOR_OF_PAYROLL(params)
			if (res) {
				this.$store.commit("set", ["modalShow", false])
			}
			if (this.amounts?.length) {
				this.amounts.forEach(el => {
					this.missingAmounts[el.orderTypeId] = el.missingAmount
				})
			}
			this.showTable = false
			this.editorUpdateAmount = null
			this.editorId = null
			this.editorOfPaysheetDetail = null
		},
		cancelModal() {
			if (this.amounts?.length) {
				this.amounts.forEach(el => {
					this.missingAmounts[el.orderTypeId] = el.missingAmount
				})
			}
			this.showTable = false
			this.$store.commit("set", ["modalShow", false])
			this.editorUpdateAmount = null
			this.editorId = null
			this.editorOfPaysheetDetail = null
		},
		delay(time) {
			return new Promise(resolve => setTimeout(resolve, time))
		},
		cancel() {
			this.isEdit = false
			this.searchInput = null
			this.listEditor = []
			this.editorSelected = {}

			this.showPopup = false
			this.$store.commit("set", ["modalShow", false])
		},
		async search() {
			// const params = {
			// 	data: { search: this.searchInput ? this.searchInput : null },
			// 	notLoading: false,
			// 	isSort: true,
			// }
			// await this.GET_EDITORS(params)
			this.listEditor = this.listEditor.filter(el => {
				const nickname = el.nickname.toLowerCase()
				const keyword = this.searchInput.toLowerCase()
				return nickname.includes(keyword)
			})
		},
		clearSearch() {
			this.searchInput = null
			this.listEditor = uniqBy([...this.item.editors].concat(this.editors), "id")
		},
		handleKeydown(event) {
			return event.preventDefault()
		},
		increase(value, index, id) {
			if (this.missingAmounts[id] <= 0) return

			this.editorOfPaysheetDetail[index].amount = value + AMOUNT_STEP
			this.missingAmounts[id] = this.missingAmounts[id] - AMOUNT_STEP
		},
		deincrease(value, index, id) {
			if (value === 0) return
			this.editorOfPaysheetDetail[index].amount = value - AMOUNT_STEP
			this.missingAmounts[id] = this.missingAmounts[id] + AMOUNT_STEP
		},
		searchDebounce: debounce(function () {
			this.search()
		}, process.env.VUE_APP_DEBOUNCE_TIME),
	},
}
</script>
<style lang="scss" scoped>
@import "@/assets/scss/variables.scss";
.editor-selected {
	color: $base-color;
	cursor: pointer;
	text-decoration: underline;
	line-height: 22px;
}
.btn__edit {
	min-width: 35px;
	margin-left: auto;
}

.list-editor {
	max-height: 300px;
	overflow-y: auto;
}

.search-input {
	position: relative;
	width: 100%;
}

.icon-close {
	position: absolute;
	top: 50%;
	right: 12px;
	transform: translateY(-50%);
	cursor: pointer;
}

.disabled-editor {
	background: $grey-5;
	cursor: not-allowed;
}
.amount-input {
	height: 45px !important;
	border-radius: 0.25rem;
	border: 1px solid $border-input;
	padding: 0.375rem 0.75rem;
	width: 100%;

	&:focus {
		outline: none;
	}
}

.amount-wrap-input {
	position: relative;
	max-width: 110px;
	margin: auto;
	min-width: 100px;
}

.icon {
	position: absolute;
	right: 12px;

	cursor: pointer;

	z-index: 10;

	svg {
		font-size: 26px;
	}

	&-up {
		top: -3px;
	}

	&-down {
		bottom: -3px;
	}
}

.editor__item {
	width: calc(100% - 55px);
	text-align: start;
	// word-break: keep-all;
}
</style>

<style lang="scss">
@import "@/assets/scss/variables.scss";
.custom-control-input[disabled] ~ .custom-control-label::before,
.custom-control-input:disabled ~ .custom-control-label::before {
	background-color: $grey-5;
}
</style>
